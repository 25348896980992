.scrolling-words-container {
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
}

.scrolling-words-box {
    height: 3rem;
    margin: auto;
    overflow: hidden;
    color: #fff;
}

.scrolling-words-box ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: scrollUp 4s infinite;
}

.scrolling-words-box ul li {
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    height: 3rem;
    list-style: none;
}

@keyframes scrollUp {

    15%,
    25% {
        transform: translateY(-20%);
    }

    40%,
    50% {
        transform: translateY(-40%);
    }

    65%,
    75% {
        transform: translateY(-60%);
    }

    90%,
    100% {
        transform: translateY(-80%);
    }
}
.btn {
    position: relative;
    display: inline-block;
    width: 300px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    text-decoration: none;
    /* font-family: sans-serif; */
    box-sizing: border-box;
    background: linear-gradient(90deg, #a653cb,
        #23bddb,
        #0088ff,
        #a653cb);
    background-size: 250%;
    border-radius: 20px;
    z-index: 1;
    transition:  0.5s;
    border: none;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 1);
}

.btn:hover {
    animation: animate 8s linear infinite;
    transform: scale(1.1);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
}

@keyframes animate {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

.btn:before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: -1;
    background: linear-gradient(90deg,#a653cb,
        #23bddb,
        #0088ff,
        #a653cb);
    background-size: 700%;
    border-radius: 40px;
    opacity: 0;
    transition: .8s;
}

.btn:hover:before {
    filter: blur(10px);
    opacity: 1;
    animation: animate 8s linear infinite;
}

