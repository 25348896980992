.page-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: background-color 200ms;
}

/* .checkbox.theme-toggle__checkbox:checked+label:after {
  transform: translateX(22px);
}

.checkbox.outline-toggle__checkbox:checked+label:after {
  transform: translateX(22px);
} */

/* .theme-toggle__checkbox:checked~label {
  color: #4c4f59;
}

.theme-toggle__checkbox:checked~label:before {
  background-color: #292a30;
}

.theme-toggle__checkbox:checked~label:before,
.theme-toggle__checkbox:checked~label:after {
  border-color: #4c4f59;
}

.theme-toggle__checkbox:checked~main {
  background-color: #1D1E22;
}

.theme-toggle__checkbox:checked~main .infinity__outline {
  stroke: #111214;
}

.outline-toggle__checkbox:checked~main .infinity__outline {
  opacity: 0;
} */

.box:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-45deg, #b84fce 0%, #d4acfb 100%);
  transform: translate3d(0px, 20px, 0) scale(0.95);
  filter: blur(20px);
  opacity: 0.7;
  transition: opacity 0.3s;
  border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.box::after {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}

.infinity path {
  fill: none;
  stroke-width: 10;
  transform-origin: 10% 50%;
}

.infinity__outline {
  stroke: #4b4453;
  -webkit-animation: hover 4000ms linear infinite;
  animation: hover 4000ms linear infinite;
  transition: opacity 600ms;
}

.infinity__tracer {
  stroke-linecap: round;
  opacity: 0.8;
  animation: tracer 1000ms linear reverse infinite;
}

.infinity__tracer--1 {
  stroke: #845ec2;
  -webkit-animation-duration: 6000ms;
  animation-duration: 6000ms;
  -webkit-animation-delay: -3960ms;
  animation-delay: -3960ms;
}

.infinity__tracer--2 {
  stroke: #d65db1;
  -webkit-animation-duration: 3000ms;
  animation-duration: 3000ms;
  -webkit-animation-delay: -1980ms;
  animation-delay: -1980ms;
}

.infinity__tracer--3 {
  stroke: #ff6f91;
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
  -webkit-animation-delay: -1320ms;
  animation-delay: -1320ms;
}

.infinity__tracer--4 {
  stroke: #ff9671;
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
  -webkit-animation-delay: -990ms;
  animation-delay: -990ms;
}

.infinity__tracer--5 {
  stroke: #ffc75f;
  -webkit-animation-duration: 1200ms;
  animation-duration: 1200ms;
  -webkit-animation-delay: -792ms;
  animation-delay: -792ms;
}

.infinity__tracer--6 {
  stroke: #2c73d2;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-delay: -660ms;
  animation-delay: -660ms;
}

@-webkit-keyframes tracer {
  0% {
    stroke-dasharray: 0, 260;
    stroke-dashoffset: -234;
  }

  25% {
    transform: translate(-2px, 2px);
  }

  50% {
    stroke-dasharray: 43.3333333333, 260;
    stroke-dashoffset: -78;
  }

  65% {
    transform: translateX(2px, -2px);
  }

  100% {
    stroke-dasharray: 4, 260;
    stroke-dashoffset: 0;
  }
}

@keyframes tracer {
  0% {
    stroke-dasharray: 0, 260;
    stroke-dashoffset: -234;
  }

  25% {
    transform: translate(-2px, 2px);
  }

  50% {
    stroke-dasharray: 43.3333333333, 260;
    stroke-dashoffset: -78;
  }

  65% {
    transform: translateX(4px, -2px);
  }

  100% {
    stroke-dasharray: 4, 260;
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes hover {
  50% {
    transform: translateY(4px) scale(1.02);
  }
}

@keyframes hover {
  50% {
    transform: translateY(4px) scale(1.02);
  }
}
